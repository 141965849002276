import React from "react";
import LinkedInIcon from "../../assets/images/footer/linkedin.svg";
import FacebookIcon from "../../assets/images/footer/facebook.svg";
import InstagramIcon from "../../assets/images/footer/instagram.svg";
import TwitterIcon from "../../assets/images/footer/twitter.svg";
import IosButton from "../../assets/images/footer/image 2.svg";
import PlayStoreButton from "../../assets/images/footer/image 1.svg";
import Button from "@mui/material/Button";
import DelnieFooter from "../../assets/images/footer/footer-delnie.svg";
import DelnieLinkedIn from "../../assets/images/footer/delnie-linkedin.svg";
import DelnieFacebook from "../../assets/images/footer/delnie-facebook.svg";
import DelnieInstagram from "../../assets/images/footer/delnie-instagram.svg";
import DelnieYoutube from "../../assets/images/footer/delnieyoutube.svg";
import Youtube from "../../assets/images/footer/youtube.svg";

import "./index.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Divider } from "@mui/material";

function Footer() {
  return (
    <>
      <div className="container-fluid footer-section-container">
        <div className="row">
          <div className="col-lg-4 footer-section-main">
            <div className="pages-container">
              <h4>Core features</h4>
              <h5>Restaurant dish highlights</h5>
              <h5>Profile Match</h5>
              <h5>Circle Discussions</h5>
            </div>
          </div>

          <div className="col-lg-2 footer-section-main">
            <div className="pages-container">
              <h4>Legal</h4>
              <Link
                to={"privacy-policy"}
                className="no-text-decoration"
                target="_blank" // Use "_blank" to open in a new tab or window
                rel="noopener noreferrer"
              >
                <h5>Privacy Policy</h5>
              </Link>
              <h5>Terms & Conditions</h5>
            </div>
          </div>
          <div className="col-lg-2 footer-section-main">
            <div className="info-container">
              <h4>Social Links</h4>
              <div className="d-flex justify-content-center align-items-center social-media-container">
                {/* <Link
                  to={""}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <Button>
                    <img src={LinkedInIcon} alt="" />
                  </Button>
                </Link> */}
                <Link
                  to={"https://www.facebook.com/favhiker"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <Button>
                    <img src={FacebookIcon} alt="" />
                  </Button>
                </Link>

                <Link
                  to={
                    "https://www.instagram.com/favhiker_official?igsh=eHB4cml0eHFkNjcy"
                  }
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <Button>
                    <img src={InstagramIcon} alt="" />
                  </Button>
                </Link>
                <Link
                  to={"https://www.youtube.com/@favhiker"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <Button>
                    <img src={Youtube} alt="" />
                  </Button>
                </Link>

                {/* <Link
                  to={"https://www.youtube.com/@favhiker"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <Button>
                    <img src={TwitterIcon} alt="" />
                  </Button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 footer-section-main">
            <div className="row app-download-container">
              <h3>Get the App</h3>
              <div className="get-apps-row">
                <Link
                  to={"https://apps.apple.com/in/app/favhiker/id6466083218"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={IosButton}
                    alt=""
                    className="img-fluid IosButton-footer"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.delnie.favhiker"
                  }
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={PlayStoreButton}
                    alt=""
                    className="img-fluid PlayStoreButton-footer"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row delnie-logo-container desktop-view-logo">
          <Divider sx={{ bgcolor: "#FFFFFF" }} />
          <div
            className="col-lg-12"
            style={{
              paddingTop: "0.5%",
              paddingLeft: "0",
              paddingBottom: "20.71px",
            }}
          >
            <Link
              to={"https://www.delnie.com/"}
              className="no-text-decoration"
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <img src={DelnieFooter} alt="" className="delnie-icons" />
            </Link>
            <Link
              to={"https://www.linkedin.com/company/delnie"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={DelnieLinkedIn} alt="" className="delnie-icons" />
              </Button>
            </Link>
            <Link
              to={"https://www.facebook.com/delnie.official?mibextid=ZbWKwL"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={DelnieFacebook} alt="" className="delnie-icons" />
              </Button>
            </Link>
            <Link
              to={"https://www.youtube.com/@delnie_official"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img
                  src={DelnieYoutube}
                  alt=""
                  className="delnie-icons img-fluid"
                />
              </Button>
            </Link>

            <Link
              to={
                "https://www.instagram.com/favhiker_official?igsh=eHB4cml0eHFkNjcy"
              }
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={DelnieInstagram} alt="" className="delnie-icons" />
              </Button>
            </Link>
          </div>

          <div className="col-lg-12 address-delnie">
            <h5>Registered Office address</h5>
            <div className="d-flex justify-content-between">
              <div className="col-lg-6 address-bengaluru">
                <h4>Bangalore </h4>{" "}
                <span>
                  {" "}
                  Delnie Innovation Lab,
                  <br /> 5th Floor, WeWork Galaxy Building 43, <br /> Residency
                  Rd, Shanthala Nagar, Ashok Nagar, <br /> Bengaluru, Karnataka,
                  560025. <br /> CIN - U74999MH2017PTC300138 <br /> GSTIN -
                  29AAGCD3699B1ZG <br />
                  Email : christo.john@delnie.com <br /> Mob : +91 9846280132
                </span>{" "}
              </div>
              <div className="col-lg-6 address-mumbai">
                <h4>Mumbai</h4>
                <span>
                  Delnie Consultancy Services,
                  <br /> Raheja Platinum, Marol CHS Rd,
                  <br /> Andheri - Kurla Road, Marol, Andheri East,
                  <br /> Mumbai, Maharashtra, 400059.
                  <br /> CIN - U74999MH2017PTC300138 <br /> GSTIN -
                  27AAGCD3699B1ZK <br /> Email : dhanush@delnie.com <br /> Mob :
                  +91 9019363435
                </span>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3">
            <div className="info-container">
              <h5>Terms & Conditions</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="info-container">
              <Link
                to={"privacy-policy"}
                className="no-text-decoration"
                target="_blank" // Use "_blank" to open in a new tab or window
                rel="noopener noreferrer"
              >
                <h5>Privacy Policy</h5>
              </Link>
            </div>
          </div> */}
        </div>
      </div>

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@ MOBILE VIEW @@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}

      <div className="container-fluid mobile-footer-section-container">
        <div className="row">
          <div className="col-6 footer-section-main">
            <div className="pages-container">
              <h4>Core features</h4>

              <h5>Restaurant dish highlights</h5>
              <h5>Profile Match</h5>
              <h5>Core Features</h5>
              <h5>Circle Discussions</h5>
            </div>
          </div>

          <div className="col-6 footer-section-main">
            <div className="pages-container">
              <h4>Legal</h4>
              <h5>Terms & Conditions</h5>
              <Link
                to={"privacy-policy"}
                className="no-text-decoration"
                target="_blank" // Use "_blank" to open in a new tab or window
                rel="noopener noreferrer"
              >
                <h5>Privacy Policy</h5>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 footer-section-main-two">
            <div className="pages-container ">
              <h4 className="get-in-touch">Get in Touch</h4>
              <h5 className="get-in-touch">Help & Support</h5>
              <h5 className="get-in-touch">Account Information</h5>
              <h5 className="get-in-touch">Security Information</h5>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 footer-section-main-three">
            <div className="row app-download-container">
              <h3 className="get-in-touch">Get the App</h3>
              <div className="col-12">
                <Link
                  to={"https://apps.apple.com/in/app/favhiker/id6466083218"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={IosButton}
                    alt=""
                    className="img-fluid IosButton-footer"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.delnie.favhiker"
                  }
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={PlayStoreButton}
                    alt=""
                    className="img-fluid PlayStoreButton-footer"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row social-media-row">
          <h3>Social Media</h3>

          <div className="col-12 d-flex  social-media-container">
            <Link
              to={"https://www.facebook.com/favhiker"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={FacebookIcon} alt="" />
              </Button>
            </Link>

            <Link
              to={"https://www.instagram.com/favhiker_official"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={InstagramIcon} alt="" />
              </Button>
            </Link>
            <Link
              to={"https://www.youtube.com/@favhiker"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={Youtube} alt="" />
              </Button>
            </Link>
          </div>
        </div>

        <div className="row text-center delnie-logo-container">
          <div className="col-12">
            <Link
              to={"https://www.delnie.com/"}
              className="no-text-decoration"
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <img src={DelnieFooter} alt="" className="delnie-icons" />
            </Link>
          </div>
        </div>
        <div className="row text-center delnie-logo-container">
          <div className="col-12">
            <Link
              to={"https://www.linkedin.com/company/delnie"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={DelnieLinkedIn} alt="" className="delnie-icons" />
              </Button>
            </Link>
            <Link
              to={"https://www.facebook.com/delnie.official?mibextid=ZbWKwL"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={DelnieFacebook} alt="" className="delnie-icons" />
              </Button>
            </Link>
            <Link
              to={"https://www.youtube.com/@delnie_official"}
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img
                  src={DelnieYoutube}
                  alt=""
                  className="delnie-icons img-fluid"
                />
              </Button>
            </Link>
            <Link
              to={
                "https://instagram.com/delnie_official?utm_source=qr&igshid=OGIxMTE0OTdkZA=="
              }
              target="_blank" // Use "_blank" to open in a new tab or window
              rel="noopener noreferrer"
            >
              <Button>
                <img src={DelnieInstagram} alt="" className="delnie-icons" />
              </Button>
            </Link>
          </div>
        </div>

        <div className="col-lg-12 address-delnie address-delnie-mobile">
          <h5>Registered Office address</h5>
          <div className="col-lg-6 address-bengaluru-mobile">
            <h4>Bangalore </h4>{" "}
            <span>
              {" "}
              Delnie Innovation Lab,
              <br /> 5th Floor, WeWork Galaxy Building 43, <br /> Residency Rd,
              Shanthala Nagar, Ashok Nagar, <br /> Bengaluru, Karnataka, 560025.{" "}
              <br /> CIN - U74999MH2017PTC300138 <br /> GSTIN - 29AAGCD3699B1ZG{" "}
              <br />
              Email : christo.john@delnie.com <br /> Mob : +91 9846280132
            </span>{" "}
          </div>
          <div className="col-lg-6 address-mumbai-mobile">
            <h4>Mumbai</h4>
            <span>
              Delnie Consultancy Services,
              <br /> Raheja Platinum, Marol CHS Rd,
              <br /> Andheri - Kurla Road, Marol, Andheri East,
              <br /> Mumbai, Maharashtra, 400059.
              <br /> CIN - U74999MH2017PTC300138 <br /> GSTIN - 27AAGCD3699B1ZK{" "}
              <br /> Email : dhanush@delnie.com <br /> Mob : +91 9019363435
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
